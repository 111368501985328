import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import SectionWithBackground from '../components/section-with-background'
import Container from '../components/container'
import SectionTitle from '../components/section-title'
import ButtonLink from '../components/button-link'
import HouseTypeList from '../components/house-type-list'

const VystavbaDomuPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { pageType: { eq: "house-construction" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { pageType: { eq: "house-type" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              slug
              title
              size
              priceFrom
              featuredImages {
                childImageSharp {
                  fluid(quality: 85, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "house-types.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      floorplanImage: file(relativePath: { eq: "floorplan.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      readyHousesImage: file(relativePath: { eq: "okamzita-vystavba.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { title, subtitle } = data.markdownRemark.frontmatter
  const { edges: houseTypes } = data.allMarkdownRemark
  const backgroundImage = data.backgroundImage.childImageSharp.fluid
  const floorplanImage = data.floorplanImage.childImageSharp.fluid
  const readyHousesImage = data.readyHousesImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={title} />

      <Hero
        backgroundImage={backgroundImage}
        title={title}
        subtitle={subtitle}
      />

      <SectionWithBackground fluid={floorplanImage} variant="light">
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2">Projekty rodinných domů</SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
              }}
            >
              Nabízíme výstavbu rodinných domů „na klíč“ v 6 základních
              variantách (typ A,B,C,D,E,F), včetně zpracování prováděcí
              projektové dokumentace, vyřízení stavebního povolení a výstavbu
              rodinného domu „na klíč“.
              <br />
              <br />V případě výstavby rodinného domu na našich pozemcích tyto
              nabízíme za zvýhodněnou cenu <strong>1&nbsp;426,- Kč</strong> za
              ㎡ včetně DPH 15%.
              <br />
              <br />
              Projekty jsou zpracovány ve standardu 2019.
            </Text>
          </Flex>
        </Container>
      </SectionWithBackground>

      <Section>
        <Container>
          <SectionTitle as="h2">Typy rodinných domů</SectionTitle>

          <HouseTypeList houseTypes={houseTypes} slugPrefix="/vystavba-domu" />
        </Container>
      </Section>

      <SectionWithBackground fluid={readyHousesImage} variant="dark" hasOverlay>
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2" color="white">
              Rodinné domy k okamžité výstavbě
            </SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Nabízíme již schválené projekty domů k výstavbě. Podívejte se na
              hotové projekty.
            </Text>

            <ButtonLink variant="white" to="/hotove-projekty">
              Hotové projekty
            </ButtonLink>
          </Flex>
        </Container>
      </SectionWithBackground>
    </Layout>
  )
}

export default VystavbaDomuPage
